//
// Variables
// --------------------------------------------------
// Bootstrap overrides and PatternFly-specific variables

// Sass compass/sprockets asset helper flag
// ----------------------
@pf-sass-asset-helper:                                              false;

// PatternFly-specific
// -------------------
@import "color-variables.less";

@applauncher-pf-dropdown-menu-border-width:                         1px;
@applauncher-pf-dropdown-menu-padding:                              9px;
@applauncher-pf-dropdown-menu-width:                                220px;
@applauncher-pf-dropdown-toggle-padding:                            7px 10px;
@applauncher-pf-icon-padding-right:                                 4px;
@applauncher-pf-menu-link-border-color:                             @color-pf-white;
@applauncher-pf-menu-link-border-color-hover:                       @color-pf-black-400;
@applauncher-pf-menu-link-border-width:                             1px;
@applauncher-pf-menu-link-color:                                    @color-pf-black-300;
@applauncher-pf-menu-link-color-disabled:                           @color-pf-black-500;
@applauncher-pf-menu-link-color-hover:                              @color-pf-blue-400;
@applauncher-pf-menu-link-padding:                                  7px 10px;
@applauncher-pf-menu-link-padding-block-list:                       15px 10px;
@applauncher-pf-menu-link-background-color-hover:                   @color-pf-black-150;
@applauncher-pf-menu-link-shadow:                                   @color-pf-black-300;
@applauncher-pf-menu-link-icon-font-size:                           2em;
@applauncher-pf-menu-link-icon-width:                               ((9 * @applauncher-pf-menu-link-icon-font-size) / 14); //Numbers to imitate fa-fw class
@blank-slate-pf-secondary-link-padding-left:                        15px;
@blank-slate-pf-secondary-link-padding-top:                         5px;
@bootstrap-switch-handle-default-bg-color:                          @color-pf-black-100;
@bootstrap-treeview-highlight-color:                                @color-pf-blue-300;
@btn-default-bg-img-start:                                          @color-pf-black-100;
@btn-default-bg-img-stop:                                           @color-pf-black-200;
@btn-xs-font-weight:                                                400;
@card-pf-accented-border-top-color:                                 @color-pf-blue-300;
@card-pf-aggregate-status-title-icon-color:                         @color-pf-black-900;
@card-pf-bg-color:                                                  @color-pf-white;
@card-pf-border-color:                                              @color-pf-black-300;
@card-pf-border-top-color:                                          transparent;
@card-pf-container-bg-color:                                        @color-pf-black-150;
@card-pf-footer-bg-color:                                           @color-pf-black-100;
@card-pf-selected-border-color:                                     @color-pf-blue-300;
@contextselector-title-width-desktop:                               210px;
@contextselector-title-width-mobile:                                170px;
@contextselector-pf-bootstrap-select-width-desktop:                 (@contextselector-title-width-desktop + 32);
@contextselector-pf-bootstrap-select-width-mobile:                  (@contextselector-title-width-mobile + 32);
@contextselector-pf-dropdown-toggle-padding-bottom:                 19px;
@contextselector-pf-dropdown-toggle-padding-left:                   10px;
@contextselector-pf-dropdown-toggle-padding-top:                    19px;
@contextselector-pf-margin-left:                                    10px;
@contextselector-pf-nav-item-iconic-padding:                        23px 20px 18px 10px;
@contextselector-pf-form-group-margin:                              0 5px 5px 5px;
@contextselector-pf-list-max-height:                                200px;
@contextselector-pf-list-li-padding:                                1px 10px;
@contextselector-pf-list-li-border-width:                           1px 0;
@donut-font-size-big:                                               30px;
@drawer-pf-top-vertical:                                            58px;
@drawer-pf-top-horizontal:                                          25px;
@dropdown-divider-margin:                                           4px 1px;
@dropdown-link-active-border-color:                                 @color-pf-blue;
@dropdown-link-hover-border-color:                                  @color-pf-blue-100;
@dropdown-link-focus-color:                                         @color-pf-white;
@flyout-transition-pf:                                              all 200ms cubic-bezier(.35, 0, .25, 1);
@font-family-monospace:                                             Menlo, Monaco, Consolas, monospace;
@font-path:                                                         "../fonts";
@footer-pf-bg-color:                                                @color-pf-black;
@footer-pf-padding-left:                                            25px;
@footer-pf-padding-top:                                             10px;
@footer-pf-height:                                                  37px;
@form-horizontal-pf-align-left-padding-left:                        30px;
@gray-light-pf:                                                     @color-pf-black-400;
@gray-pf:                                                           @color-pf-black-700;
@icon-font-name-fa:                                                 "FontAwesome";
@icon-font-name-pf:                                                 "PatternFlyIcons-webfont";
@icon-prefix:                                                       pficon;
@img-bg-login:                                                      "bg-login.jpg";
@img-path:                                                          "../img";
@img-spinner:                                                       "spinner.gif";
@img-spinner-inverse:                                               "spinner-inverse.gif";
@img-spinner-inverse-lg:                                            "spinner-inverse-lg.gif";
@img-spinner-inverse-sm:                                            "spinner-inverse-sm.gif";
@img-spinner-inverse-xs:                                            "spinner-inverse-xs.gif";
@img-spinner-lg:                                                    "spinner-lg.gif";
@img-spinner-sm:                                                    "spinner-sm.gif";
@img-spinner-xs:                                                    "spinner-xs.gif";
@input-border-hover:                                                @color-pf-blue-200;
@input-border-focus:                                                @color-pf-blue-400;
@list-pf-border-color:                                              @color-pf-black-200;
@list-pf-active-border-color:                                       @color-pf-black-400;
@list-pf-header-background-color:                                   @color-pf-blue-25;
@list-pf-hover-background-color:                                    @color-pf-blue-25;
@list-pf-main-content-width:                                        70%; // sets the proportional width of the main content vs the additional content
@list-pf-padding:                                                   20px;
@list-view-accented-border:                                         @color-pf-blue-300;
@list-view-active-bg:                                               @color-pf-blue-50;
@list-view-active-border:                                           @color-pf-black-400;
@list-view-divider:                                                 @color-pf-black-300;
@list-view-hover-bg:                                                @color-pf-blue-25;
@list-group-disabled-bg:                                            @color-pf-black-100;
@list-group-top-border:                                             @color-pf-black-200;
@login-bg-color:                                                    @color-pf-black;
@login-container-bg-color-rgba:                                     fade(@color-pf-white, 5.5%);
@modal-about-pf-bg-img:                                             "bg-modal-about-pf.png";
@modal-title-padding-horizontal:                                    18px;
@modal-title-padding-vertical:                                      10px;
@navbar-pf-active-color:                                            @color-pf-black-150;
@navbar-pf-alt-active-color:                                        @color-pf-white;
@navbar-pf-alt-bg-color:                                            @color-pf-black;
@navbar-pf-alt-bg-img:                                              "bg-navbar-pf-alt.svg";
@navbar-pf-bg-color:                                                @color-pf-black;
@navbar-pf-border-color:                                            @color-pf-blue-300;
@navbar-pf-color:                                                   @color-pf-black-300;
@navbar-pf-height:                                                  60px;
@navbar-pf-icon-bar-bg-color:                                       @color-pf-white;
@navbar-pf-item-hover-bg-color:                                     rgba(255, 255, 255, .14);
@navbar-pf-item-open-bg-color:                                      rgba(255, 255, 255, .24);
@navbar-pf-item-active-color:                                       @color-pf-white;
@navbar-pf-item-icon-padding-right:                                 4px;
@navbar-pf-navbar-navbar-brand-min-width:                           270px;
@navbar-pf-navbar-navbar-brand-padding:                             8px 0 7px;
@navbar-pf-navbar-navbar-persistent-bg-color:                       @color-pf-black-150;
@navbar-pf-navbar-navbar-persistent-border-color:                   @color-pf-black-300;
@navbar-pf-vertical-active-color:                                   @navbar-pf-item-active-color;
@navbar-pf-vertical-bg-color:                                       #1d1d1d;
@navbar-pf-vertical-bg-repeat:                                      no-repeat;
@navbar-pf-vertical-bg-size:                                        auto 100%;
@navbar-pf-vertical-border-color:                                   @color-pf-blue-300;
@navbar-pf-vertical-border-style:                                   solid;
@navbar-pf-vertical-border-width:                                   2px;
@navbar-pf-vertical-color:                                          @color-pf-black-300;
@navbar-pf-vertical-height:                                         60px;
@navbar-pf-vertical-icon-bar-bg-color:                              @color-pf-white;
@navbar-pf-vertical-navbar-brand-color:                             @color-pf-white;
@navbar-pf-vertical-navbar-brand-icon-margin:                       0 15px 0 0;
@navbar-pf-vertical-navbar-brand-margin:                            0 0 0 25px;
@navbar-pf-vertical-navbar-brand-min-height:                        35px;
@navbar-pf-vertical-navbar-brand-max-height:                        35px;
@navbar-pf-vertical-navbar-brand-line-height:                       34px;
@navbar-pf-vertical-navbar-brand-name-breakpoint:                   480px;
@navbar-pf-vertical-navbar-brand-name-margin:                       0 15px 0 0;
@navbar-pf-vertical-navbar-brand-padding:                           11px 0 12px;
@navbar-pf-vertical-navbar-toggle-margin:                           13px 15px;
@navbar-pf-vertical-navbar-toggle-icon-bar-hover-bg:                @color-pf-white;
@navbar-pf-vertical-nav-item-iconic-padding:                        21px 12px;
@nav-pf-container-transition-period:                                250ms;
@nav-pf-menu-transition-period:                                     100ms;
@nav-pf-vertical-active-before-color:                               @color-pf-blue-300;
@nav-pf-vertical-active-bg-color:                                   @color-pf-black-800;
@nav-pf-vertical-active-border-color:                               @color-pf-black-900;
@nav-pf-vertical-active-color:                                      @color-pf-white;
@nav-pf-vertical-active-font-weight:                                600;
@nav-pf-vertical-active-icon-color:                                 @color-pf-blue-300;
@nav-pf-vertical-badge-color:                                       @color-pf-white;
@nav-pf-vertical-badge-bg-color:                                    @color-pf-black-900;
@nav-pf-vertical-bg-color:                                          @color-pf-black-900;
@nav-pf-vertical-border-color:                                      @color-pf-black-900;
@nav-pf-vertical-collapsed-width:                                   75px;
@nav-pf-vertical-color:                                             @color-pf-black-300;
@nav-pf-vertical-font-weight:                                       400;
@nav-pf-vertical-icon-color:                                        @color-pf-black-600;
@nav-pf-vertical-icon-width:                                        24px;
@nav-pf-vertical-item-border-color:                                 @color-pf-black;
@nav-pf-vertical-link-height:                                       63px;
@nav-pf-vertical-link-padding:                                      17px 20px 17px 25px;
@nav-pf-vertical-secondary-active-color:                            @color-pf-white;
@nav-pf-vertical-secondary-active-bg-color:                         @color-pf-black-700;
@nav-pf-vertical-secondary-active-icon-color:                       @color-pf-blue-300;
@nav-pf-vertical-secondary-badge-color:                             @color-pf-white;
@nav-pf-vertical-secondary-badge-bg-color:                          @color-pf-black-900;
@nav-pf-vertical-secondary-border-color:                            @color-pf-blue-300;
@nav-pf-vertical-secondary-color:                                   @color-pf-white;
@nav-pf-vertical-secondary-header-margin:                           18px 20px 10px 20px;
@nav-pf-vertical-secondary-indicator-color:                         @color-pf-black-600;
@nav-pf-vertical-secondary-indicator-padding:                       14px 0;
@nav-pf-vertical-secondary-item-color:                              @color-pf-black-300;
@nav-pf-vertical-secondary-item-padding:                            0 0 5px 0;
@nav-pf-vertical-secondary-link-height:                             63px;
@nav-pf-vertical-secondary-link-padding:                            4px 0 2px 0;
@nav-pf-vertical-secondary-list-header-margin:                      30px 20px 10px 20px;
@nav-pf-vertical-tertiary-active-color:                             @color-pf-white;
@nav-pf-vertical-tertiary-active-bg-color:                          @color-pf-black-800;
@nav-pf-vertical-tertiary-indicator-padding:                        0;
@nav-pf-vertical-tertiary-bg-color:                                 @color-pf-black-700;
@nav-pf-vertical-tertiary-color:                                    #fff;
@nav-pf-vertical-tertiary-header-margin:                            18px 20px 10px 20px;
@nav-pf-vertical-tertiary-list-header-margin:                       30px 20px 10px 20px;
@nav-pf-vertical-tertiary-item-padding:                             0 0 5px 0;
@nav-pf-vertical-tertiary-item-color:                               @color-pf-black-300;
@nav-pf-vertical-tertiary-link-margin:                              0 20px 0 20px;
@nav-pf-vertical-tertiary-link-padding:                             4px 0 2px 0;
@nav-pf-vertical-tertiary-badge-color:                              @color-pf-white;
@nav-pf-vertical-tertiary-badge-bg-color:                           @color-pf-black-900;
@nav-pf-vertical-width:                                             200px;
@toast-notification-top:                                            80px;
@pagination-padding-small-vertical:                                 0;
@panel-default-border-color:                                        @color-pf-black-400;
@pficon-var-add-circle-o:                                           "\e61b";
@pficon-var-applications:                                           "\e936";
@pficon-var-arrow:                                                  "\e929";
@pficon-var-asleep:                                                 "\e925";
@pficon-var-automation:                                             "\e937";
@pficon-var-blueprint:                                              "\e915";
@pficon-var-build:                                                  "\e902";
@pficon-var-builder-image:                                          "\e800";
@pficon-var-bundle:                                                 "\e918";
@pficon-var-catalog:                                                "\e939";
@pficon-var-chat:                                                   "\e928";
@pficon-var-close:                                                  "\e60b";
@pficon-var-cloud-security:                                         "\e903";
@pficon-var-cloud-tenant:                                           "\e904";
@pficon-var-cluster:                                                "\e620";
@pficon-var-connected:                                              "\e938";
@pficon-var-container-node:                                         "\e621";
@pficon-var-cpu:                                                    "\e927";
@pficon-var-degraded:                                               "\e91b";
@pficon-var-delete:                                                 "\e611";
@pficon-var-disconnected:                                           "\e93c";
@pficon-var-domain:                                                 "\e919";
@pficon-var-drag-drop:                                              "\e950";
@pficon-var-edit:                                                   "\e60a";
@pficon-var-enhancement:                                            "\e93a";
@pficon-var-enterprise:                                             "\e906";
@pficon-var-equalizer:                                              "\e610";
@pficon-var-error-circle-o:                                         "\e926";
@pficon-var-export:                                                 "\e616";
@pficon-var-flag:                                                   "\e603";
@pficon-var-flavor:                                                 "\e907";
@pficon-var-filter:                                                 "\e943";
@pficon-var-folder-close:                                           "\e607";
@pficon-var-folder-open:                                            "\e606";
@pficon-var-help:                                                   "\e605";
@pficon-var-history:                                                "\e93b";
@pficon-var-home:                                                   "\e618";
@pficon-var-image:                                                  "\e61f";
@pficon-var-import:                                                 "\e615";
@pficon-var-info:                                                   "\e92b";
@pficon-var-infrastructure:                                         "\e93d";
@pficon-var-integration:                                            "\e948";
@pficon-var-in-progress:                                            "\e92c";
@pficon-var-key:                                                    "\e924";
@pficon-var-kubernetes:                                             "\e627";
@pficon-var-locked:                                                 "\e923";
@pficon-var-maintenance:                                            "\e92d";
@pficon-var-memory:                                                 "\e908";
@pficon-var-messages:                                               "\e603";
@pficon-var-middleware:                                             "\e917";
@pficon-var-migration:                                              "\e92e";
@pficon-var-monitoring:                                             "\e944";
@pficon-var-network:                                                "\e909";
@pficon-var-network-range:                                          "\e94a";
@pficon-var-off:                                                    "\e92f";
@pficon-var-ok:                                                     "\e602";
@pficon-var-on:                                                     "\e931";
@pficon-var-on-running:                                             "\e930";
@pficon-var-openshift:                                              "\e626";
@pficon-var-optimize:                                               "\e93e";
@pficon-var-orders:                                                 "\e93f";
@pficon-var-paused:                                                 "\e932";
@pficon-var-pending:                                                "\e933";
@pficon-var-plugged:                                                "\e940";
@pficon-var-port:                                                   "\e945";
@pficon-var-private:                                                "\e914";
@pficon-var-print:                                                  "\e612";
@pficon-var-process-automation:                                     "\e949";
@pficon-var-project:                                                "\e905";
@pficon-var-rebalance:                                              "\e91c";
@pficon-var-rebooting:                                              "\e934";
@pficon-var-regions:                                                "\e90a";
@pficon-var-registry:                                               "\e623";
@pficon-var-remove:                                                 "\e611";
@pficon-var-replicator:                                             "\e624";
@pficon-var-repository:                                             "\e90b";
@pficon-var-resource-pool:                                          "\e90c";
@pficon-var-resources-almost-empty:                                 "\e91d";
@pficon-var-resources-almost-full:                                  "\e912";
@pficon-var-resources-full:                                         "\e92a";
@pficon-var-restart:                                                "\e617";
@pficon-var-route:                                                  "\e625";
@pficon-var-running:                                                "\e614";
@pficon-var-save:                                                   "\e601";
@pficon-var-satellite:                                              "\e94b";
@pficon-var-screen:                                                 "\e600";
@pficon-var-search:                                                 "\e921";
@pficon-var-security:                                               "\e946";
@pficon-var-server:                                                 "\e90d";
@pficon-var-server-group:                                           "\e91a";
@pficon-var-service:                                                "\e61e";
@pficon-var-service-catalog:                                        "\e941";
@pficon-var-services:                                               "\e947";
@pficon-var-settings:                                               "\e610";
@pficon-var-sort-common-asc:                                        "\e94e";
@pficon-var-sort-common-desc:                                       "\e94f";
@pficon-var-spinner:                                                "\e614";
@pficon-var-spinner2:                                               "\e613";
@pficon-var-storage-domain:                                         "\e90e";
@pficon-var-template:                                               "\e94c";
@pficon-var-tenant:                                                 "\e916";
@pficon-var-thumb-tack-o:                                           "\e920";
@pficon-var-topology:                                               "\e608";
@pficon-var-trend-down:                                             "\e900";
@pficon-var-trend-up:                                               "\e901";
@pficon-var-unlocked:                                               "\e922";
@pficon-var-unplugged:                                              "\e942";
@pficon-var-unknown:                                                "\e935";
@pficon-var-user:                                                   "\e91e";
@pficon-var-users:                                                  "\e91f";
@pficon-var-vcenter:                                                "\e94d";
@pficon-var-virtual-machine:                                        "\e90f";
@pficon-var-volume:                                                 "\e910";
@pficon-var-warning-triangle-o:                                     "\e913";
@pficon-var-zone:                                                   "\e911";
@progress-description-label-width:                                  85px;
@sidebar-pf-bg:                                                     @color-pf-black-100;
@sidebar-pf-border-color:                                           @color-pf-black-300;
@skiplink-pf-top:                                                   12px;
@skiplink-pf-left:                                                  10px;
@table-border-hover:                                                @color-pf-blue-200;
@table-cell-padding-bottom:                                         3px;
@table-cell-padding-top:                                            2px;
// Reference variables declared in block above
@bootstrap-switch-bg-color-start:                                   @btn-default-bg-img-start;
@bootstrap-switch-bg-color-stop:                                    @btn-default-bg-img-stop;
@fa-font-path:                                                      @font-path;
@navbar-pf-navbar-header-border-color:                              lighten(@navbar-pf-bg-color, 15%);
@navbar-pf-navbar-nav-active-active-bg-color:                       lighten(@navbar-pf-bg-color, 8%);
@navbar-pf-navbar-nav-active-active-open-bg-color:                  lighten(@navbar-pf-bg-color, 11%);
@navbar-pf-navbar-nav-active-bg-color:                              lighten(@navbar-pf-bg-color, 12.5%);
@navbar-pf-navbar-primary-bg-color-start:                           lighten(@navbar-pf-bg-color, 10%); // #474C50
@navbar-pf-navbar-primary-bg-color-stop:                            @navbar-pf-bg-color; // #383F43
@navbar-pf-navbar-utility-border-color:                             lighten(@navbar-pf-bg-color, 15.5%);
@navbar-pf-navbar-utility-color:                                    @navbar-pf-color;
@navbar-pf-navbar-utility-hover-bg-color:                           lighten(@navbar-pf-bg-color, 12.5%);
@nav-pf-vertical-secondary-bg-color:                                @nav-pf-vertical-active-bg-color;
@nav-tabs-color:                                                    @gray-pf;
// Reference variables declared in block above
@navbar-pf-navbar-primary-context-bg-color-start:                   lighten(@navbar-pf-navbar-primary-bg-color-start, 8.5%);
@navbar-pf-navbar-primary-context-bg-color-stop:                    lighten(@navbar-pf-navbar-primary-bg-color-stop, 11%);
@navbar-pf-navbar-primary-hover-bg-color-start:                     lighten(@navbar-pf-navbar-primary-bg-color-start, 10%);
@navbar-pf-navbar-primary-hover-bg-color-stop:                      lighten(@navbar-pf-navbar-primary-bg-color-stop, 10%);
@navbar-pf-navbar-primary-hover-border-color:                       lighten(@navbar-pf-navbar-header-border-color, 20%);
@navbar-pf-navbar-primary-context-border-color:                     lighten(@navbar-pf-navbar-header-border-color, 8%);
@navbar-pf-navbar-primary-context-border-top-color:                 lighten(@navbar-pf-navbar-header-border-color, 7%);
@navbar-pf-navbar-utility-hover-border-color:                       lighten(@navbar-pf-navbar-utility-border-color, 5%);
@navbar-pf-navbar-utility-open-bg-color:                            lighten(@navbar-pf-navbar-utility-hover-bg-color, 7.5%);
@navbar-pf-navbar-utility-open-border-color:                        lighten(@navbar-pf-navbar-utility-hover-border-color, 5%);
// Reference variables declared in block above
@navbar-pf-navbar-primary-active-bg-color-start:                    lighten(@navbar-pf-navbar-primary-hover-bg-color-start, 5%);
@navbar-pf-navbar-primary-active-bg-color-stop:                     lighten(@navbar-pf-navbar-primary-hover-bg-color-stop, 7.5%);
@navbar-pf-navbar-primary-active-border-color:                      lighten(@navbar-pf-navbar-primary-hover-border-color, 5%);
@navbar-pf-navbar-primary-context-hover-bg-color-start:             lighten(@navbar-pf-navbar-primary-context-bg-color-start, 5%);
@navbar-pf-navbar-primary-context-hover-bg-color-stop:              lighten(@navbar-pf-navbar-primary-context-bg-color-stop, 7.5%);
@navbar-pf-navbar-primary-context-hover-border-color:               lighten(@navbar-pf-navbar-primary-context-border-color, 5%);
@navbar-pf-navbar-primary-context-hover-border-top-color:           lighten(@navbar-pf-navbar-primary-context-border-top-color, 6%);
// Reference variables declared in block above
@navbar-pf-navbar-primary-context-active-bg-color-start:            lighten(@navbar-pf-navbar-primary-context-hover-bg-color-start, 5%);
@navbar-pf-navbar-primary-context-active-bg-color-stop:             lighten(@navbar-pf-navbar-primary-context-hover-bg-color-stop, 7.5%);
@navbar-pf-navbar-primary-context-active-border-color:              lighten(@navbar-pf-navbar-primary-context-hover-border-color, 5%);
@navbar-pf-navbar-primary-context-active-border-top-color:          lighten(@navbar-pf-navbar-primary-context-hover-border-top-color, 6%);
// References variable declared in block above
@navbar-pf-navbar-primary-context-active-border-right-color:        @navbar-pf-navbar-primary-context-active-border-color;
@slider-line-height:                                                16px;
@slider-horizontal-width:                                           100%;
@slider-primary-top:                                                @color-pf-black-100;
@slider-primary-bottom:                                             @color-pf-black-200;
@slider-secondary-top:                                              @color-pf-blue-400;
@slider-secondary-bottom:                                           @color-pf-blue-300;
@tree-list-view-pf-padding-left:                                    45px;

// Alternate navigation layout - Kept for backwards compatibility
// --------------------------------------------------------------
@footer-pf-alt-bg-color:                                            @footer-pf-bg-color;
@footer-pf-alt-padding-left:                                        @footer-pf-padding-left;
@footer-pf-alt-padding-top:                                         @footer-pf-padding-top;
@footer-pf-alt-height:                                              @footer-pf-height;
@img-path-alt:                                                      @img-path;
@nav-pf-vertical-alt-active-color:                                  @color-pf-blue-300;
@nav-pf-vertical-alt-bg-color:                                      @color-pf-white;
@nav-pf-vertical-alt-badge-color:                                   @color-pf-white;
@nav-pf-vertical-alt-border-color:                                  @sidebar-pf-border-color;
@nav-pf-vertical-alt-collapsed-width:                               75px;
@nav-pf-vertical-alt-link-height:                                   63px;
@nav-pf-vertical-alt-link-padding:                                  17px 20px 17px 25px;
@nav-pf-vertical-alt-width:                                         250px;
@navbar-pf-alt-bg-repeat:                                           @navbar-pf-vertical-bg-repeat;
@navbar-pf-alt-bg-size:                                             @navbar-pf-vertical-bg-size;
@navbar-pf-alt-border-color:                                        @navbar-pf-vertical-border-color;
@navbar-pf-alt-border-style:                                        @navbar-pf-vertical-border-style;
@navbar-pf-alt-border-width:                                        @navbar-pf-vertical-border-width;
@navbar-pf-alt-color:                                               @navbar-pf-vertical-color;
@navbar-pf-alt-height:                                              @navbar-pf-vertical-height;
@navbar-pf-alt-nav-item-iconic-padding:                             @navbar-pf-vertical-nav-item-iconic-padding;
@navbar-pf-alt-navbar-brand-color:                                  @navbar-pf-vertical-navbar-brand-color;
@navbar-pf-alt-navbar-brand-icon-margin:                            @navbar-pf-vertical-navbar-brand-icon-margin;
@navbar-pf-alt-navbar-brand-margin:                                 @navbar-pf-vertical-navbar-brand-margin;
@navbar-pf-alt-navbar-brand-min-height:                             @navbar-pf-vertical-navbar-brand-min-height;
@navbar-pf-alt-navbar-brand-name-breakpoint:                        @navbar-pf-vertical-navbar-brand-name-breakpoint;
@navbar-pf-alt-navbar-brand-name-margin:                            @navbar-pf-vertical-navbar-brand-name-margin;
@navbar-pf-alt-navbar-brand-padding:                                @navbar-pf-vertical-navbar-brand-padding;
@navbar-pf-alt-navbar-toggle-margin:                                @navbar-pf-vertical-navbar-toggle-margin;
@navbar-pf-alt-navbar-toggle-icon-bar-hover-bg:                     @navbar-pf-vertical-navbar-toggle-icon-bar-hover-bg;

// Login page
// -------------------
@login-pf-brand-margin-top:                                               0px;
@login-pf-page-padding-top:                                               20px;
@login-pf-brand-desktop-margin-top:                                       22px;
@login-pf-page-header-margin-bottom:                                      20px;
@login-pf-page-header-desktop-margin-bottom:                              40px;
@login-pf-page-header-paragraph-margin-top:                               20px;
@login-pf-card-pf-padding:                                 10px 20px 30px 20px;
@login-pf-card-pf-desktop-padding:                         20px 40px 30px 40px;
@login-pf-card-pf-max-width:                                             840px;
@login-pf-checkbox-label-margin-bottom:                                   15px;
@login-pf-dropdown-toggle-padding-right:                                  15px;
@login-pf-btn-primary-margin-top:                                         40px;
@login-pf-header-margin-bottom:                                           20px;
@login-pf-header-desktop-margin-bottom:                                   10px;
@login-pf-signup-margin-top:                                              40px;
@login-pf-signup-a-margin-left:                                           10px;
@login-pf-page-footer-links-margin-top:                                   60px;
@login-pf-page-footer-links-li-margin-right:                              20px;
@login-pf-page-footer-sso-margin-top:                                     40px;
@login-pf-page-footer-sso-logos-li-margin:                                5px;
@login-pf-page-footer-padding-bottom-mobile:                              50px;
@login-pf-page-footer-padding-bottom-desktop:                             100px;
@login-pf-page-accounts-margin:                                           20px;
@login-pf-accounts-section-heading-margin-top:                            30px;
@login-pf-accounts-section-heading-margin-bottom:                         30px;
@login-pf-accounts-section-heading-desktop-margin-bottom:                 15px;
@login-pf-accounts-section-heading-desktop-padding-right:                 40px;
@login-pf-accounts-section-heading-desktop-padding-left:                  40px;
@login-pf-accounts-link-margin-bottom:                                    15px;
@login-pf-accounts-link-img-margin-right:                                 10px;
@login-pf-accounts-link-a-padding:                                        7px;
@login-pf-accounts-more-caret-margin-left:                                5px;
@login-pf-social-margin:                                           40px 0 0 0;


// Bootstrap overrides
// -------------------
@alert-info-border:                                                 @color-pf-black-500;
@alert-link-font-weight:                                            500;
@alert-padding:                                                     11px;
@alert-margin-left:                                                 250px;
@alert-margin-right:                                                250px;
@body-bg:                                                           @color-pf-white;
@border-radius-base:                                                1px;
@brand-danger:                                                      @color-pf-red;
@brand-info:                                                        @color-pf-blue-500;
@brand-primary:                                                     @color-pf-blue-300;
@brand-success:                                                     @color-pf-green;
@brand-warning:                                                     @color-pf-orange;
@breadcrumb-bg:                                                     transparent;
@breadcrumb-separator:                                              "\f105";
@bs-datetimepicker-timepicker-font-size:                            1em;
@bs-datetimepicker-btn-hover-bg:                                    transparent;
@btn-danger-bg:                                                     @color-pf-red-200;
@btn-danger-border:                                                 @color-pf-red-300;
@btn-default-border:                                                @color-pf-black-400;
@btn-font-weight:                                                   600;
@btn-primary-bg:                                                    @color-pf-blue;
@btn-primary-border:                                                @color-pf-blue-500;
@caret-width-base:                                                  0;
@code-bg:                                                           @color-pf-blue-50;
@dropdown-border:                                                   @color-pf-black-400;
@dropdown-divider-bg:                                               @color-pf-black-200;
@dropdown-link-active-color:                                        @color-pf-white;
@dropdown-link-hover-bg:                                            @color-pf-blue-50;
@font-family-base:                                                  "Open Sans", Helvetica, Arial, sans-serif;
@font-size-base:                                                    12px;
@gray-dark:                                                         lighten(@color-pf-black, 20%);   // #333
@gray-darker:                                                       lighten(@color-pf-black, 13.5%); // #222
@gray-light:                                                        lighten(@color-pf-black, 60%);   // #999
@gray-lighter:                                                      lighten(@color-pf-black, 93.5%); // #eee
@grid-gutter-width:                                                 40px;
@icon-font-path:                                                    "../fonts/";
@input-bg-disabled:                                                 @color-pf-black-150;
@input-border:                                                      @color-pf-black-400;
@line-height-base:                                                  1.66666667; // 20/12
@link-color:                                                        @color-pf-blue;
@list-group-border:                                                 @color-pf-black-150;
@list-group-border-radius:                                          0;
@list-group-hover-bg:                                               @color-pf-blue-50;
@link-hover-color:                                                  @color-pf-blue-500;
@nav-tabs-border-color:                                             @color-pf-black-200;
@nav-tabs-link-hover-border-color:                                  transparent;
@padding-large-horizontal:                                          10px;
@padding-base-horizontal:                                           6px;
@padding-base-vertical:                                             2px;
@padding-large-vertical:                                            6px;
@pager-border-radius:                                               0;
@pager-disabled-color:                                              @color-pf-black-500;
@pagination-bg:                                                     @color-pf-black-150;
@pagination-border:                                                 @color-pf-black-400;
@pagination-hover-bg:                                               @color-pf-black-200;
@panel-inner-border:                                                @color-pf-black-300;
@popover-arrow-color:                                               @color-pf-white;
@popover-arrow-outer-color:                                         @color-pf-black-400;
@popover-border-color:                                              @color-pf-black-400;
@popover-border-bottom-color:                                       @color-pf-black-150;
@popover-border-width:                                              10px;
@popover-border-top-width:                                          0;
@popover-max-width:                                                 220px;
@popover-position-top:                                              -20px;
@popover-title-bg:                                                  @color-pf-black-150;
@pre-bg:                                                            @color-pf-black-100;
@progress-bg:                                                       @color-pf-black-200;
@table-bg-accent:                                                   @color-pf-black-150;
@table-bg-hover:                                                    @color-pf-blue-50;
@table-border-color:                                                @color-pf-black-300;
@table-cell-padding:                                                10px;
@tooltip-arrow-width:                                               8px;
@tooltip-bg:                                                        @color-pf-black-800;
@tooltip-max-width:                                                 220px;
// Reference variables declared in block above
@alert-danger-bg:                                                   lighten(@color-pf-red-100, 55%);
@alert-danger-border:                                               @brand-danger;
@alert-danger-text:                                                 @gray-dark;
@alert-info-bg:                                                     @color-pf-black-150;
@alert-info-text:                                                   @gray-dark;
@alert-success-bg:                                                  lighten(@color-pf-green-100, 8%);
@alert-success-border:                                              @brand-success;
@alert-success-text:                                                @gray-dark;
@alert-warning-bg:                                                  lighten(@color-pf-orange-100, 8%);
@alert-warning-border:                                              @brand-warning;
@alert-warning-text:                                                @gray-dark;
@text-danger-p-padding-top:                                         10px;
@badge-border-radius:                                               @border-radius-base;
@border-radius-large:                                               @border-radius-base;
@border-radius-small:                                               @border-radius-base;
@breadcrumb-active-color:                                           @gray-pf;
@breadcrumb-color:                                                  @gray-pf;
@btn-default-bg:                                                    @gray-lighter;
@btn-default-color:                                                 @gray-pf;
@caret-width-large:                                                 @caret-width-base;
@code-color:                                                        @color-pf-blue-600;
@dropdown-fallback-border:                                          @dropdown-border;
@dropdown-link-active-bg:                                           @link-color;
@dropdown-link-hover-color:                                         @gray-pf;
@font-size-h1:                                                      ceil((@font-size-base * 2)); // ~24px
@font-size-h2:                                                      ceil((@font-size-base * 1.8333)); // ~22px
@font-size-h3:                                                      ceil((@font-size-base * 1.3333)); // ~16px
@font-size-h5:                                                      ceil((@font-size-base * 1.0833)); // ~13px
@font-size-large:                                                   ceil((@font-size-base * 1.1666)); // ~14px
@font-size-small:                                                   ceil((@font-size-base * .9166)); // ~11px
@input-color:                                                       @gray-dark;
@nav-tabs-active-link-hover-color:                                  @link-color;
@nav-tabs-justified-link-border-color:                              @nav-tabs-border-color;
@nav-pf-vertical-badges-width:                                      (@nav-pf-vertical-width + 50px);
@padding-small-horizontal:                                          @padding-base-horizontal;
@padding-small-vertical:                                            @padding-base-vertical;
@panel-group-pf-danger-heading-bg:                                  @alert-danger-bg;
@panel-group-pf-primary-heading-bg:                                 lighten(@brand-primary, 38%);
@panel-group-pf-success-heading-bg:                                 @alert-success-bg;
@panel-group-pf-warning-heading-bg:                                 @alert-warning-bg;
@panel-group-pf-info-heading-bg:                                    lighten(desaturate(spin(@brand-info, 13), 45), 60%);
@panel-danger-border:                                               @brand-danger;
@panel-danger-heading-bg:                                           @brand-danger;
@panel-info-border:                                                 @brand-info;
@panel-info-heading-bg:                                             @brand-info;
@panel-primary-border:                                              @brand-primary;
@panel-primary-text:                                                @body-bg;
@panel-success-border:                                              @brand-success;
@panel-success-heading-bg:                                          @brand-success;
@panel-warning-border:                                              @brand-warning;
@panel-warning-heading-bg:                                          @brand-warning;
@spinner-size:                                                      (@font-size-base * 2);
@spinner-size-lg:                                                   (@font-size-base * 2.5);
@spinner-size-sm:                                                   (@font-size-base * 1.5);
@spinner-size-xs:                                                   @font-size-base;
@state-warning-text:                                                @brand-warning;
@state-danger-text:                                                 @brand-danger;
// Reference variable declared in block above
@panel-danger-text:                                                 @panel-primary-text;
@panel-info-text:                                                   @panel-primary-text;
@panel-success-text:                                                @panel-primary-text;
@panel-warning-text:                                                @panel-primary-text;

// PatternFly-specific variables based on Bootstrap overides
// ---------------------------------------------------------
@bootstrap-switch-bg-color:                                         @btn-default-bg;
@btn-danger-bg-img-start:                                           @brand-danger;
@btn-danger-bg-img-stop:                                            @btn-danger-bg;
@btn-primary-bg-img-start:                                          @brand-primary;
@btn-primary-bg-img-stop:                                           @btn-primary-bg;
@dropdown-link-focus-bg:                                            @link-color;
@line-height-computed:                                              floor((@font-size-base * @line-height-base));
@nav-pf-vertical-icon-size:                                         (@font-size-base + 8);
@notification-badge-color:                                          @color-pf-blue-400;
@progress-sm:                                                       (@line-height-computed - 6);
@progress-xs:                                                       (@line-height-computed - 14);
@syntax-1:                                                          @btn-danger-bg;
@syntax-2:                                                          @gray-dark;
@syntax-3:                                                          @brand-success;
@syntax-4:                                                          @brand-info;
@tooltip-font-size:                                                 @font-size-base;
