/*
 *  Copyright 2022 Red Hat
 *
 *  Licensed under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License.
 *  You may obtain a copy of the License at
 *
 *      https://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software
 *  distributed under the License is distributed on an "AS IS" BASIS,
 *  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 *  See the License for the specific language governing permissions and
 *  limitations under the License.
 */
/* styles used in configuration or runtime views */

.hal-execution-duration {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
}

.hal-execution-time {
  flex: 1 0 auto;
  margin-right: 40px;
}

.hal-conf-changes-additional-info {
  flex: 1 0 auto;
}

.rest-resources p {
  margin-bottom: 5px;
}

.pending {
  cursor: progress;
}